<template>
  <div v-if="pageLoading">
    <profile-loading></profile-loading>
    <Dialog
      v-model:visible="userBlocked"
      :modal="true"
      :closable="false"
      header="Info"
      class="blocked-user-dialog"
    >
      <div class="p-d-flex p-flex-column p-jc-end p-mx-5">
        <div class="p-mb-5">You do not have access to view this profile.</div>
        <div class="p-d-flex p-jc-center">
          <Button label="Go Home" @click="redirectToHome()" />
        </div>
      </div>
    </Dialog>
  </div>
  <div v-else class="p-grid">
    <div class="p-col-12 p-lg-6 p-md-12 style">
      <div class="card1 p-pb-3">
        <profile-card
          :profile="profile"
          @updatedProfileCard="updatedProfileCard"
        />
        <div
          v-if="!authUser.is_admin && authUser.slug !== profile.slug"
          class="p-grid p-d-flex p-jc-between p-mt-2 interactive-container"
        >
          <div
            class="
              p-col-6 p-sm-4 p-d-flex p-jc-evenly p-ai-center
              interactive-content-1
            "
          >
            <i
              v-tooltip.top="shortlist ? 'Shortlisted' : 'Shortlist'"
              :disabled="block"
              :class="shortlist ? 'fas fa-heart' : 'far fa-heart'"
              @click="shortlistProfile()"
            />
            <i
              id="link1"
              v-tooltip.top="'Share'"
              class="fas fa-share-alt"
              @click="toggle($event)"
            />
            <OverlayPanel ref="shareProfile" for="link">
              <div class="p-d-flex p-jc-between p-ai-center share-container">
                <span class="p-mr-5">
                  {{ share }}
                </span>
                <div @click="copyToClipboard">
                  <i class="fas fa-copy p-p-2" />
                </div>
              </div>
            </OverlayPanel>
          </div>
          <!-- if no interest made before -->
          <div
            v-if="!profile.interest_type && !block"
            class="p-col-8 p-d-flex p-jc-end interest-action p-my-1"
          >
            <Button
              :disabled="sentInterestLoading"
              label="Send Interest"
              :icon="
                sentInterestLoading ? 'fa fa-spinner fa-spin' : 'pi pi-send'
              "
              @click="sendInterest"
            />
          </div>
          <!-- sent interest -->
          <div
            v-if="profile.interest_type === 'sent'"
            class="
              p-col-6 p-sm-4 p-md-5 p-lg-4 p-d-flex p-jc-end p-ai-center
              interest-status
              p-px-2
            "
          >
            <!-- interest status -->
            <span
              v-if="profile.interest_status === 'requested'"
              class="p-text-center"
              >Awating for response</span
            >
            <span
              v-if="profile.interest_status === 'accepted'"
              class="p-text-center success-text"
              >Sent Interest Accepted</span
            >
            <span
              v-if="profile.interest_status === 'rejected'"
              class="p-text-center danger-text"
              >Sent Interest Rejected</span
            >
          </div>
          <div
            v-if="
              profile.interest_type === 'sent' &&
              profile.interest_status === 'requested'
            "
            class="
              p-col-12 p-sm-4 p-md-3 p-lg-4 p-d-flex p-jc-end
              interest-action
              p-px-0
            "
          >
            <Button
              label="Undo sent interest"
              :disabled="sentInterestLoading"
              :icon="
                sentInterestLoading ? 'fa fa-spinner fa-spin' : 'pi pi-undo'
              "
              class="p-py-2"
              @click="undoInterest"
            />
          </div>
          <!-- recieved interest -->
          <div
            v-if="profile.interest_type === 'received'"
            class="
              p-col-6 p-md-4 p-lg-6 p-xl-3 p-d-flex p-jc-end p-ai-center
              interest-status
            "
          >
            <span
              v-if="profile.interest_status === 'requested'"
              class="p-text-center"
              >Interest Received</span
            >
            <span
              v-if="profile.interest_status === 'rejected'"
              class="p-text-center danger-text"
              >Received interest rejected</span
            >
            <span
              v-if="profile.interest_status === 'accepted'"
              class="p-text-center success-text"
              >Received interest accepted</span
            >
          </div>
          <div
            v-if="
              profile.interest_type === 'received' &&
              profile.interest_status === 'requested'
            "
            class="
              p-col-12 p-md-4 p-lg-12 p-xl-5 p-d-flex p-jc-center
              received-action
            "
          >
            <Button
              :disabled="rejectLoading"
              class="p-mr-2 p-button-outlined"
              label="Reject"
              :icon="rejectLoading ? 'fa fa-spinner fa-spin' : 'pi pi-times'"
              @click="updateInterest('rejected')"
            />
            <Button
              :disabled="acceptLoading"
              :icon="acceptLoading ? 'fa fa-spinner fa-spin' : 'pi pi-check'"
              class="p-ml-2"
              label="Accept"
              @click="updateInterest('accepted')"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-lg-6 p-md-12 style">
      <div class="card1">
        <div class="header-style c-form">
          <h3>Personal Details</h3>
        </div>
        <view-personal-details :profile="profile" />
      </div>
    </div>
    <div class="p-col-12 p-lg-6 p-md-12 p-flex-column style">
      <div class="card1 p-mb-3">
        <div class="header-style c-form">
          <h3>Basic Details</h3>
        </div>
        <view-basic-details :profile="profile" />
      </div>
      <div class="card1">
        <div class="header-style c-form">
          <h3>Education and Work Details</h3>
        </div>
        <view-education-details :profile="profile" />
      </div>
    </div>
    <div class="p-col-12 p-lg-6 p-md-12 style">
      <div class="card1">
        <div class="header-style c-form">
          <h3>Family Details</h3>
        </div>
        <view-family-details :profile="profile" />
      </div>
    </div>
    <div class="p-col-12 p-lg-6 p-md-12 style">
      <div
        class="card1"
        :class="`${
          hideDetails ? (authUser.is_admin ? '' : 'hide-details') : ''
        } `"
      >
        <div class="header-style c-form">
          <h3>Contact Details</h3>
        </div>
        <view-contact-show-details
          :profile="authUser.is_admin ? profile : contact_details"
          :show-details="showDetails"
          :interest-status="profile.interest_status"
        />
        <div>
          <Button
            v-if="showDetails"
            icon-pos="left"
            label="Show Details"
            class="p-button-sm my-profile-btn hideDetails-btn"
            @click="toggleEdit()"
          />
        </div>
      </div>
    </div>
    <div class="p-col-12 p-lg-6 p-md-12 style">
      <div class="card1">
        <div class="header-style c-form">
          <h3>Social Details</h3>
        </div>
        <view-social-details :profile="profile" />
      </div>
    </div>
    <div
      v-if="authUser.is_admin"
      :class="canShowApprovalButtons ? 'admin-approval' : null"
      class="p-d-flex p-jc-between p-ai-center del-btn-only"
    >
      <delete-profile-dialog v-if="authUser.is_admin" class="delete-prof-btn" />
      <div v-if="canShowApprovalButtons" class="pending-approval-btns p-mr-2">
        <Button
          label="Approve Profile"
          class="admin-approval-btn"
          @click="validateProfile(true)"
        />
        <Button
          :disabled="isRejected"
          label="Reject Profile"
          class="p-button-secondary admin-approval-btn"
          @click="showRejectionModal"
        />
      </div>
    </div>
    <div
      v-if="!authUser.is_admin && authUser.slug !== profile.slug"
      class="p-d-flex p-jc-start p-ai-center block-btn-container"
    >
      <Button
        v-if="block"
        v-tooltip.top="
          'Unblock now to prevent loss of authorization to view this profile'
        "
        :icon="blockLoading ? 'fa fa-spinner fa-spin' : 'pi pi-eye'"
        label="Unblock"
        class="unblock"
        :disabled="blockLoading"
        @click="unblockUser()"
      />
      <Button
        v-else
        :icon="blockLoading ? 'fa fa-spinner fa-spin' : 'fas fa-ban'"
        label="Block"
        class="block"
        :disabled="blockLoading"
        @click="toggleBlockDialog()"
      />
      <i
        v-if="block"
        v-tooltip.top="'You can find blocked users on MailBox -> Blocked'"
        class="p-ml-3 pi pi-info-circle unblock-info"
      />
    </div>
    <Dialog
      v-model:visible="displayRejectionModal"
      :modal="true"
      :closable="true"
      :show-header="true"
      class="rejection-dialog"
      :draggable="false"
      @hide="hideRejectionModal"
    >
      <template #header>
        <h3>Reason for rejection:</h3>
      </template>
      <div>
        <Textarea
          v-model="rejectionComment"
          rows="5"
          cols="30"
          class="rejection-text"
        />
      </div>
      <template #footer>
        <Button
          label="Reject"
          class="p-mr-2 p-button-secondary"
          @click="validateProfile(false)"
        />
      </template>
    </Dialog>
    <Dialog
      v-model:visible="showBlock"
      :modal="true"
      header="Block User"
      class="block-dialog"
      :dismissable-mask="true"
    >
      <div class="p-d-flex p-flex-column p-ai-start p-p-0 p-mx-2 p-my-0">
        <div class="p-mb-1">Decline interest sent/received (if exists)</div>
        <div class="p-mb-1">Delist (if shortlisted)</div>
        <div class="p-mb-1">
          Can’t view profile untill unblocked(after leaving this page)
        </div>
      </div>
      <div class="p-d-flex p-jc-end p-mt-3">
        <Button
          class="p-mr-3 p-button-outlined"
          label="Yes"
          @click="blockUser()"
        />
        <Button label="No" autofocus @click="toggleBlockDialog()" />
      </div>
    </Dialog>
  </div>
</template>

<script>
import { profileInteractivesMixin } from '@/mixins/profileInteractivesMixin.js';
import ViewPersonalDetails from '@/components/myprofile/personalDetails/ViewPersonalDetails.vue';
import ViewContactShowDetails from '@/components/myprofile/contactDetails/ViewContactShowDetails.vue';
import ViewBasicDetails from '@/components/myprofile/basicDetails/ViewBasicDetails.vue';
import ViewSocialDetails from '@/components/myprofile/socialDetails/ViewSocialDetails.vue';
import profileCard from '@/components/myprofile/profileCard.vue';
import ViewFamilyDetails from '@/components/myprofile/familyDetails/ViewFamilyDetails.vue';
import ViewEducationDetails from '@/components/myprofile/educationDetails/ViewEducationDetails.vue';
import '@/assets/profile.scss';
import { profileApprovalMixin } from '@/mixins/profileApprovalMixin.js';
import ProfileLoading from '@/components/views/ProfileLoading.vue';
import { mapGetters } from 'vuex';
import DeleteProfileDialog from '@/components/DeleteProfileDialog.vue';
import { redirectMixin } from '@/mixins/redirectMixin';
export default {
  components: {
    ViewPersonalDetails,
    ViewBasicDetails,
    ViewSocialDetails,
    profileCard,
    ViewFamilyDetails,
    ViewEducationDetails,
    ProfileLoading,
    DeleteProfileDialog,
    ViewContactShowDetails,
  },
  mixins: [profileApprovalMixin, redirectMixin, profileInteractivesMixin],
  data() {
    return {
      showBlock: false,
      userBlocked: false,
      pageLoading: true,
      displayRejectionModal: false,
      hideDetails: true,
      rejectionComment: '',
      contact_details: {
        contact_relation: '',
        contact_name: '',
        primary_contact_number: '',
      },
      profile: {
        contact_relation: '',
        contact_name: '',
        email: '',
        primary_contact_number: '',
        about: '',
        address_country: '',
        address_state: '',
        age: 0,
        comment: '',
        created_for: '',
        date_of_birth: '',
        diet: '',
        display_photo_url: '',
        education: {
          attributes: {
            highest_qualification: '',
          },
        },
        family: {
          attributes: {
            father: '',
            mother: '',
            father_status: '',
            mother_status: '',
            family_type: '',
            no_of_married_brothers: 0,
            no_of_married_sisters: 0,
            no_of_unmarried_brothers: 0,
            no_of_unmarried_sisters: 0,
          },
        },
        first_name: '',
        gender: '',
        is_active: false,
        is_admin: false,
        is_approved: false,
        last_name: '',
        marital_status: '',
        mother_tongue: '',
        occupation: {
          attributes: {
            salary: 0,
            job_type: '',
          },
        },
        physical_info: {
          attributes: {
            weight: 0,
            height: 0.0,
            medical_conditions: '',
            differently_abled: null,
          },
        },
        profile_photo_url: [],
        sent_interest: false,
        shortlisted: false,
        social_class: {
          attributes: {
            religion: '',
            caste: '',
            sub_caste: '',
            citizenship: '',
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(['authUser']),
    showDetails() {
      return this.authUser.is_admin ? false : this.hideDetails;
    },
  },
  created() {
    this.getProfile();
  },
  methods: {
    toggleBlockDialog() {
      this.showBlock = !this.showBlock;
    },
    redirectToHome() {
      this.goToDashboard();
      this.userBlocked = false;
    },
    getProfile() {
      let that = this;
      this.$http
        .get(`/users/${this.$route.params.slugId}`)
        .then((res) => {
          that.profile = res.data.data.attributes;
          that.pageLoading = false;
          that.block = that.profile['block?'];
          that.shortlist = that.profile['shortlisted?'];
          that.interestId = that.profile['interest_id'];
          that.fetchShareLink();
        })
        .catch((err) => {
          if (err.response.status === 400) {
            that.userBlocked = true;
          } else {
            that.pageLoading = false;
            that.$router.push('/notfound');
          }
        });
    },
    getContactDetails() {
      let that = this;
      this.$http
        .get('/users/contact_details', {
          params: { slug: this.$route.params.slugId },
        })
        .then((response) => {
          that.contact_details = response.data.data.attributes;
        });
    },
    updatedProfileCard(res) {
      this.profile = res.data.data.attributes;
    },
    validateProfile(isApproved) {
      let that = this;
      let params = { is_approved: isApproved };
      if (!isApproved) {
        params.comment = this.rejectionComment;
      }
      this.$http
        .patch(`users/${this.$route.params.slugId}/approve_profile`, params)
        .then(() => {
          that.profile.is_approved = isApproved;
          that.profile.approved_by_name = that.authUser.first_name;
          that.profile.approved_by_id = that.authUser.id;
          if (isApproved) {
            that.$moshaToast('Successfully approved', {
              type: 'success',
              showIcon: true,
              timeout: 2000,
            });
          } else {
            that.profile.comment = that.rejectionComment;
            that.hideRejectionModal();
            that.$moshaToast('Profile rejected', {
              type: 'danger',
              showIcon: true,
              timeout: 2000,
            });
          }
        });
    },
    showRejectionModal() {
      this.displayRejectionModal = true;
    },
    hideRejectionModal() {
      this.rejectionComment = '';
      this.displayRejectionModal = false;
    },
    toggleEdit() {
      this.getContactDetails();
      this.hideDetails = !this.hideDetails;
    },
  },
};
</script>

<style scoped lang="scss">
.unblock-info {
  background: #d8d6ff;
  font-size: 1.14rem;
  border-radius: 50%;
  padding: 0.5rem;
  color: $primaryColor;
}
.success-text {
  color: $successTextColor;
}
.danger-text {
  color: $dangerTextColor;
}
.interest-status {
  span {
    word-break: break-word;
  }
}
.interactive-content-1 {
  i {
    cursor: pointer;
    font-size: 20px;
    background: #f0f0f0;
    border-radius: 5px;
    padding: 0.5rem;
    &:disabled {
      opacity: 0.7;
      background: #d7d7d7;
    }
  }
}
.header-style {
  display: flex;
  justify-content: space-between;
}
.admin-approval {
  width: 100%;
  .admin-approval-btn {
    font-size: 14px;
    margin: 0.3rem 0.69rem;
  }
}
.del-btn-only {
  margin: 1rem 0 1rem 0.625rem;
}
@media screen and (min-width: 1200px) {
  .received-action {
    justify-content: end;
    margin: 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .received-action {
    justify-content: end;
  }
}
@media screen and (max-width: 500px) {
  .admin-approval {
    flex-direction: column-reverse;
    align-items: center;
    .pending-approval-btns {
      display: flex;
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 575px) {
  .interactive-container {
    justify-content: center;
  }
  .interest-status {
    justify-content: center;
    span {
      text-align: center;
    }
  }
  .interest-action {
    justify-content: center;
  }
  .received-action {
    justify-content: center;
  }
}
.rejection-text {
  width: 100%;
}
.hide-details {
  background-color: rgba(0, 0, 0, 0.4);
  position: relative;
}
.hideDetails-btn {
  position: absolute;
  top: 50%;
  left: 40%;
}
</style>
<style lang="scss">
.block-dialog {
  min-width: 300px;
  width: 450px;
  max-width: 90%;
  .p-dialog-content {
    padding: 0 1.5rem 2rem 1.5rem;
  }
}
.block-btn-container {
  padding: 8px;
  .block,
  .block:enabled,
  .block:hover {
    color: $dangerTextColor;
    border: 1px solid $dangerbackgroundColor;
    background: $dangerbackgroundColor;
  }
  .unblock,
  .unblock:enabled,
  .unblock:hover {
    color: $successTextColor;
    border: 1px solid $successBackgroundColor;
    background: $successBackgroundColor;
  }
  .p-button:focus {
    box-shadow: none;
  }
}
.share-container {
  span {
    max-width: 250px;
    word-break: break-all;
  }
  i {
    border: 1px solid $primaryColor;
    color: $primaryColor;
    border-radius: 5px;
    cursor: pointer;
  }
}
.blocked-user-dialog {
  font-size: 20px;
  .p-dialog-header {
    .p-dialog-title {
      font-size: 22px;
    }
  }
}
.rejection-dialog {
  width: 40rem;
  height: 20rem;
  .p-inputtext {
    resize: none;
  }
}

@media screen and (max-width: $mobileView) {
  .admin-approval {
    .del-prof-btn {
      margin: 0.3rem 0;
    }
  }
}
</style>
