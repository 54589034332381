export const profileInteractivesMixin = {
  data() {
    return {
      sentInterestLoading: false,
      acceptLoading: false,
      rejectLoading: false,
      blockLoading: false,
      block: false,
      shortlist: false,
      share: '',
      interestId: null,
      interestStatus: '',
    };
  },
  methods: {
    shortlistProfile() {
      let that = this;
      this.$http
        .patch(`/users/${that.authUser.slug}/add_or_remove_shortlist`, {
          shortlisted: that.shortlist,
          user_id: that.profile.id,
        })
        .then(() => {
          that.shortlist = !that.shortlist;
        });
    },
    blockUser() {
      this.blockLoading = true;
      let that = this;
      this.$http
        .post(`users/${that.authUser.slug}/block_profile`, {
          user_id: that.profile.id,
        })
        .then(() => {
          that.block = true;
          that.shortlist = false;
          that.blockLoading = false;
          that.$moshaToast('You have blocked ' + this.profile.first_name, {
            type: 'danger',
            showIcon: true,
            timeout: 2000,
          });
          if (that.profile.interest_type === 'sent') {
            that.profile.interest_type = null;
            that.profile.interest_status = null;
          } else {
            that.profile.interest_status = 'rejected';
          }
        })
        .catch(() => {
          that.blockLoading = false;
        });
      this.toggleBlockDialog();
    },
    unblockUser() {
      this.blockLoading = true;
      let that = this;
      this.$http
        .patch(`users/${that.authUser.slug}/unblock_profile`, {
          user_id: that.profile.id,
        })
        .then(() => {
          that.block = false;
          that.blockLoading = false;
        });
    },
    fetchShareLink() {
      let that = this;
      this.$http
        .post('/users/share_profile', { target_id: that.profile.id })
        .then((res) => {
          that.share = res.data.link;
        });
    },
    copyToClipboard() {
      const clipboardData = window.clipboardData || navigator.clipboard;
      clipboardData.writeText(this.share);
      this.$moshaToast('Copied to clipboard', {
        type: 'success',
        showIcon: true,
        timeout: 2000,
      });
      this.clipBoard = false;
    },
    sendInterest() {
      this.sentInterestLoading = true;
      let that = this;
      this.$http
        .post('/interests', {
          interest: {
            from_user_id: that.authUser.id,
            to_user_id: that.profile.id,
          },
        })
        .then((res) => {
          that.interestId = res.data.id;
          that.profile.interest_type = 'sent';
          that.profile.interest_status = 'requested';
          this.sentInterestLoading = false;
        })
        .catch(() => {
          this.sentInterestLoading = false;
        });
    },
    undoInterest() {
      this.sentInterestLoading = true;
      let that = this;
      this.$http
        .delete(`interests/${that.interestId}`)
        .then(() => {
          that.profile.interest_type = null;
          that.profile.interest_status = null;
          that.interestId = null;
          this.sentInterestLoading = false;
        })
        .catch(() => {
          that.sentInterestLoading = false;
        });
    },
    updateInterest(status) {
      this.acceptLoading = status === 'accepted';
      this.rejectLoading = status === 'rejected';
      let that = this;
      this.$http
        .patch(`/interests/${that.interestId}/update_status`, {
          interest: { status: status },
          block_user: false,
        })
        .then(() => {
          that.profile.interest_status = status;
          this.acceptLoading = false;
          this.rejectLoading = false;
        });
    },
    toggle(event) {
      if (this.share === '') {
        this.$moshaToast('Something went wrong', {
          type: 'danger',
          showIcon: true,
          timeout: 2000,
        });
      } else {
        this.$refs.shareProfile.toggle(event);
      }
    },
  },
};
